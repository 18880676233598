import React from 'react';
import {Link} from 'gatsby';
import useDarkMode from 'use-dark-mode';

// import icons
import {FaGithub} from 'react-icons/fa';
import {ImBlog} from 'react-icons/im';
import {SiGooglescholar} from 'react-icons/si';

const ItemContainer = (props) => {
  let initDarkMode = false;
  if (typeof window !== `undefined`) {
    initDarkMode = JSON.parse(localStorage.getItem('darkMode'));
  }
  const darkMode = useDarkMode(initDarkMode);

  return (
    <div className='item-holder'>
      <a
        href='https://github.com/katie-codes-io'
        target='_blank'
        rel='noreferrer'
        className={darkMode.value ? 'item-dark' : 'item'}
        style={{alignSelf: 'flex-start'}}
      >
        <FaGithub size='3em' className={darkMode.value ? 'social-icon-dark' : 'social-icon'} />
        <h3>GitHub, where I've been working on various projects</h3>
      </a>
      <a
        href='https://scholar.google.co.uk/citations?user=qPLdCNYAAAAJ&hl=en'
        target='_blank'
        rel='noreferrer'
        className={darkMode.value ? 'item-dark' : 'item'}
        style={{alignSelf: 'flex-end'}}
      >
        <SiGooglescholar size='3em' className={darkMode.value ? 'social-icon-dark' : 'social-icon'} />
        <h3>Google Scholar, which lists my publications</h3>
      </a>
      <Link className={darkMode.value ? 'item-dark' : 'item'} to='/blog' style={{alignSelf: 'flex-start'}}>
        <ImBlog size='3em' className={darkMode.value ? 'social-icon-dark' : 'social-icon'} />
        <h3>My blog, which I don't update very often!</h3>
      </Link>
    </div>
  );
};

export default ItemContainer;
