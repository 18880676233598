import React from 'react';
import {graphql} from 'gatsby';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import ItemContainer from '../components/itemContainer';

class Index extends React.Component {
  render() {
    const {data} = this.props;
    const blogTitle = data.site.siteMetadata.title;

    return (
      <Layout title={blogTitle} index={true}>
        <SEO title={blogTitle} />
        <div className='app-container'>
          <h2>Welcome to my website, check out:</h2>
          <ItemContainer />
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
